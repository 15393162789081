











import { Vue, Component } from "vue-property-decorator";
import { namespace } from "vuex-class";

const auth = namespace("authentication");

@Component
export default class Login extends Vue {
  @auth.Action login: any;
  valid = true;

  form = {
    identifier: "",
    password: ""
  };

  rules = {
    identifier: [(v: any) => !!v || "Identifier is required"],
    password: [(v: any) => !!v || "Password is required"]
  };

  submit(): void {
    this.login(this.form);
  }
}
